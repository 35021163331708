.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-header {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app-header .presents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}

.gn-logo {
  background: #ffffff;
  mask-image: url("./images/gnarled-helix-logo-large.png");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  width: 80px;
  height: 80px;
  pointer-events: none;
}

@keyframes title-pop {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

h1 {
  font-size: 6vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  line-height: 6vw;
  color: #ffffff;
  text-shadow: 5px 5px #114488;
  animation-name: title-pop;
  animation-duration: 1s;
  animation-fill-mode: both
}

h1 .llc-text {
  font-size: 0.5em;
}

.about {
  position: absolute;
  font-size: 3vw;
  display: block;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.about p {
  max-width: 75vw;
}

.game-title {
  font-family: "Geo";
}

.backgrounds {
  pointer-events: none;
  z-index: -10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.backgrounds > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    top: -24px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.backgrounds .bg-bg {
  z-index: -1;
  background-image: url("./images/os-key-bg.png");
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: both
}

.backgrounds .bg-aeon {
  z-index: 0;
  background-image: url("./images/os-key-aeon.png");
  animation-name: fade-in;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: both
}

.backgrounds .bg-gaian {
  z-index: 1;
  background-image: url("./images/os-key-gaian.png");
  animation-name: fade-in;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: both
}

.backgrounds .bg-sai {
  z-index: 2;
  background-image: url("./images/os-key-sai.png");
  animation-name: fade-in;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: both
}

.backgrounds .bg-player {
  z-index: 5;
  background-image: url("./images/os-key-player.png");
  animation-name: fade-in;
  animation-delay: 1.5s;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: both
}

.backgrounds .bg-fenronir {
  z-index: 3;
  background-image: url("./images/os-key-fenronir.png");
  animation-name: fade-in;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: both
}

.backgrounds .bg-adana {
  z-index: 4;
  background-image: url("./images/os-key-adana.png");
  animation-name: fade-in;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: both
}
