@font-face {
  font-family: "Geo";
  src: url("./fonts/Geo-Regular.woff2") format("woff2"),
    url("./fonts/Geo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  background-color: #171e29;
  color: #ffffff;
}

h1 {
  font-family: "Geo";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
